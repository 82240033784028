.HotelList {
  margin-top: 20px;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
}

.HotelList__Item {
  flex: 0 0 auto;
  width: 95%;
}

.HotelList__Message {
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  text-align: center;
  font-weight: 500;
  font-size: 1.5rem;
  background-color: var(--blue);
  color: var(--white);
  border-radius: 5px;
}

/* sm */
@media screen and (min-width: 576px) {
  .HotelList__Item {
    width: 47%;
  }
}

/* md */
@media screen and (min-width: 768px) {
}

/* lg */
@media screen and (min-width: 992px) {
}

/* xl */
@media screen and (min-width: 1200px) {
  .HotelList__Item {
    width: 31%;
  }
}

/* xxl */
@media screen and (min-width: 1400px) {
}
